import { render, staticRenderFns } from "./footerPlayer.vue?vue&type=template&id=06b85dfe&scoped=true&"
import script from "./footerPlayer.vue?vue&type=script&lang=js&"
export * from "./footerPlayer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b85dfe",
  null
  
)

export default component.exports